import dayjs from 'dayjs'
import Cookies from 'js-cookie'

import { customFetchFile } from '../../api/customFetch'
import Box from '../../components/Box'
import Button from '../../components/Button'
import { CellFormatWrapper } from '../../components/DataTable/components'
import PercentileDataSwitch from '../../components/PercentileDataSwitch'
import PercentiledDateModal from '../../components/PercentileModal'
import RadioGroup from '../../components/RadioGroup'
import Text from '../../components/Text'
import {
  AllStableEx,
  AllStableExData,
  ContractType,
  DerivativeEx,
  DerivativeExData,
  ExchangeDetailsConfig,
  OpenInterestChange,
  Percentile,
  PercentileData,
} from '../../types/business'
import { downloadFile } from '../../utils'
import { percentileDataDays, percentileDataTypes } from '../config'
import { OVERVIEW } from '../dataCategory'

type AllQuarterData = {
  data: Record<string, Record<string, DerivativeExData[]>>
  exchanges: string[]
  columns: string[]
}

type AllCoinbaseData = {
  data: Record<string, DerivativeExData[]>
  columns: string[]
  rows: string[]
}

type AllPercentileData = {
  data: Record<string, Record<string, PercentileData[]>>
  exchanges: string[]
  rows: string[]
  timestamp: number
}

type AllStableData = {
  data: Record<string, AllStableExData[]>
  exchanges: string[]
  rows: string[]
}

type FuturesStreamData = {
  columns: string[] // available exchanges in the futures table
  allData: DerivativeEx[] // data for the current quarter
  allQuarterData: AllQuarterData
  allStableData: AllStableData
  coinbaseData: AllCoinbaseData
  allPercentileData: AllPercentileData
  otherData: DerivativeEx[] // data for the next quarter e.g. 1230 based on 0930
  openInterestChanges: OpenInterestChange[] // for top 20 changes
  tabName: string
  showApy: boolean
}

const tabs = [
  // 'Stable',
  'Overview',
  'Percentile',
  'CQ',
  'NQ',
  'Top 20 in Change of Open Interest',
]

export const FuturesConfig: ExchangeDetailsConfig<FuturesStreamData> = {
  title: 'Futures',
  dataCategory: OVERVIEW.Futures,
  tabs: {
    defaultValue: tabs[0],
    labels: tabs,
    gridProperties: {
      Overview: {
        gridTemplateColumns: '3fr 4fr',
        gridTemplateAreas: `
          'all header'
          'btc stable'
          'eth coinbase'
          'sol empty'
        `,
      },
    },
  },
  tables: [
    {
      type: 'DerivativeExApy',
      dataKey: 'allQuarterData',
      tabeleHeader: () => {
        return (
          <Box
            css={{
              flexRowCenter: 'flex-start',
              gap: '$medium',
              marginBottom: '$medium',
              // float: 'left',
              zIndex: 1,
              // alignItems: 'last baseline',
            }}
          >
            {/* <Heading>BTC</Heading> */}
            <RadioGroup
              css={{
                marginLeft: '10px',
              }}
              initialValue={Cookies.get('excell_switch')}
              stateList={['Prem', 'APR', 'Absolute']}
              stateChanged={(state: string) =>
                Cookies.set('excell_switch', state, { sameSite: 'strict' })
              }
            />
            <CellFormatWrapper css={{ textAlign: 'center' }}>
              <Text css={{ gridArea: '1 / 1 / span 2 / span 2' }}>
                {Cookies.get('excell_switch')}
              </Text>
              <Text css={{ gridArea: '3 / 1 / span 2 / span 2' }}>
                (Funding Rate)
              </Text>
              <Text>Volume 24H</Text>
              <Text>Open Interest</Text>
            </CellFormatWrapper>
          </Box>
        )
      },
      transformData: transformFnFactory('btc'),
      tableProps: {
        title: 'BTC',
        contractType: ContractType.Future,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.allQuarterData.exchanges,
      }),
      tab: tabs[0],
      gridArea: 'btc',
    },
    {
      type: 'DerivativeExApy',
      dataKey: 'allQuarterData',
      transformData: transformFnFactory('eth'),
      tableProps: {
        title: 'ETH',
        contractType: ContractType.Future,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.allQuarterData.exchanges,
      }),
      tab: tabs[0],
      gridArea: 'eth',
    },
    {
      type: 'DerivativeExApy',
      dataKey: 'allQuarterData',
      transformData: transformFnFactory('sol'),
      tableProps: {
        title: 'SOL',
        contractType: ContractType.Future,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.allQuarterData.exchanges,
      }),
      tab: tabs[0],
      gridArea: 'sol',
    },
    {
      type: 'AllStableEx',
      dataKey: 'allStableData',
      tabeleHeader: () => {
        return (
          <Box
            css={{
              height: 54,
              marginBottom: '$medium',
            }}
          />
        )
      },
      transformData: transformFnFactoryAllStable(),
      tableProps: {
        title: 'Stable',
      },
      tablePropsExtractor: data => ({
        columnLabels: data.allStableData.exchanges,
      }),
      tab: tabs[0],
      gridArea: 'stable',
    },
    {
      type: 'DerivativeExApy',
      dataKey: 'coinbaseData',
      transformData: transformFnFactoryDetailed(),
      tableProps: {
        title: 'Coinbase Deriv',
        contractType: ContractType.Future,
        width: '160PX',
      },
      tablePropsExtractor: data => ({
        columnLabels: data.coinbaseData.columns,
      }),
      tab: tabs[0],
      gridArea: 'coinbase',
    },
    {
      type: 'FuturePrem',
      dataKey: 'allPercentileData',
      tabeleHeader: props => {
        const { allPercentileData } = props
        return (
          <Box
            css={{
              flexRowCenter: 'flex-start',
              gap: '$medium',
              marginBottom: '$medium',
            }}
          >
            <PercentileDataSwitch options={percentileDataDays} comp="day" />
            <PercentileDataSwitch options={percentileDataTypes} comp="type" />
            <Text>Last data update time:{` `}</Text>
            <Text css={{ fontSize: '$4', fontWeight: 'bold' }}>
              {dayjs(allPercentileData.timestamp).format('YYYY/MM/DD HH:mm')}
            </Text>
            <PercentiledDateModal />
          </Box>
        )
      },
      transformData: transformFnFactoryAllPercentile('btc'),
      tableProps: {
        title: 'BTC',
        contractType: ContractType.Future,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.allPercentileData.exchanges,
      }),
      tab: tabs[1],
      gridArea: 'btc',
    },
    {
      type: 'FuturePrem',
      dataKey: 'allPercentileData',
      transformData: transformFnFactoryAllPercentile('eth'),
      tableProps: {
        title: 'ETH',
        contractType: ContractType.Future,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.allPercentileData.exchanges,
      }),
      tab: tabs[1],
      gridArea: 'eth',
    },
    {
      type: 'DerivativeExApy',
      dataKey: 'allData',
      tabeleHeader: () => {
        const download = async () => {
          const file = await customFetchFile('/Derivs/GetFiles?page=Swaps', {})
          downloadFile(file, 'swaps.xlsx')
        }
        return (
          <Box
            css={{
              flexRowCenter: 'flex-start',
              gap: '$medium',
              marginBottom: '$medium',
            }}
          >
            <Button onClick={download}>Extract</Button>
          </Box>
        )
      },
      tableProps: {
        contractType: ContractType.Future,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.columns,
      }),
      tab: tabs[2],
      gridArea: 'all',
    },

    {
      type: 'DerivativeExApy',
      dataKey: 'otherData',
      tabeleHeader: () => {
        return (
          <Box
            css={{
              flexRowCenter: 'flex-start',
              gap: '$medium',
              marginBottom: '$medium',
            }}
          />
        )
      },
      tableProps: {
        contractType: ContractType.Future,
      },
      tablePropsExtractor: data => ({
        columnLabels: data.columns,
      }),
      tab: tabs[3],
    },
    {
      type: 'OpenInterestChange',
      dataKey: 'openInterestChanges',
      tableProps: null,
      tab: tabs[4],
    },
  ],
}

function transformFnFactory(
  coin: string
): (data: AllQuarterData) => DerivativeEx[] {
  return (data: AllQuarterData): DerivativeEx[] => {
    const result = data.columns.map(instKey => {
      const dataInstKey = instKey.startsWith('SWAP')
        ? instKey.replace(/^SWAP/, 'swap')
        : instKey

      const rawDatas = data?.data?.[coin]?.[dataInstKey] || []
      const finalDatas = data.exchanges.map(exchange => {
        const searchKey = exchange === 'Binance' ? 'BinanceFuture' : exchange
        return rawDatas.find(x => x.exchange === searchKey) || null
      })
      return {
        alias: instKey,
        commonId: instKey,
        datas: finalDatas,
      } as DerivativeEx
    })
    return result
  }
}

function transformFnFactoryDetailed(): (
  data: AllCoinbaseData
) => DerivativeEx[] {
  return (data: AllCoinbaseData): DerivativeEx[] => {
    const result = data.rows.map(instKey => {
      const dataInstKey = instKey.startsWith('SWAP')
        ? instKey.replace(/^SWAP/, 'swap')
        : instKey
      const rawDatas = data?.data?.[dataInstKey] || []
      const finalDatas = data.columns.map(coin => {
        const searchKey = coin
        return rawDatas.find(x => x.exchange === searchKey) || null
      })
      return {
        alias: instKey,
        commonId: instKey,
        datas: finalDatas,
      } as DerivativeEx
    })
    return result
  }
}

function transformFnFactoryAllStable(): (data: AllStableData) => AllStableEx[] {
  return (data: AllStableData): AllStableEx[] => {
    const result = data.rows.map(row => {
      const key =
        (Object.keys(data.data) as Array<string>).find(
          k => k.toUpperCase() === row.toUpperCase()
        ) || ''
      const rawDatas = data?.data?.[key] || []
      const finalDatas = data.exchanges.map(exchange => {
        const searchKey = exchange
        return rawDatas.find(x => x.exchange === searchKey) || null
      })
      return {
        alias: row,
        commonId: row,
        datas: finalDatas,
      } as AllStableEx
    })
    return result
  }
}

function transformFnFactoryAllPercentile(
  coin: string
): (data: AllPercentileData) => Percentile[] {
  return (data: AllPercentileData): Percentile[] => {
    const result = data.rows.map(instKey => {
      const rowDatas = data?.data?.[coin] || []
      const key =
        (Object.keys(rowDatas) as Array<string>).find(
          k => k.toUpperCase() === instKey.toUpperCase()
        ) || ''
      return {
        alias: instKey,
        commonId: instKey,
        datas: rowDatas[key],
      } as Percentile
    })
    return result
  }
}

import { useMemo } from 'react'

import {
  ContractType,
  DerivativeEx,
  DerivativeExData,
} from '../../types/business'
import DataTable, { DataTableColumns } from './base'
import { ExDataCell } from './components'

type DerivativeExTableProps = {
  columnLabels: string[]
  data: DerivativeEx[]
  title?: string
  contractType: ContractType
  width?: string
}

type TransformedData = {
  instrumentId: DerivativeEx['alias']
  exchangeData: Record<string, DerivativeExData | null>
}

const transformData = (data: DerivativeEx[], columnLabels: string[]) => {
  const transformedData = data.map(el => {
    const exchangeData = columnLabels.reduce((acc, exchangeLabel, index) => {
      return {
        ...acc,
        [exchangeLabel]: el.datas[index],
      }
    }, {}) as DerivativeExData
    return {
      instrumentId: el.alias || el.commonId,
      exchangeData,
    } as unknown as TransformedData
  })

  const btcEthData: TransformedData[] = []
  const restData: TransformedData[] = []

  transformedData.forEach(el => {
    const instrumentId = el.instrumentId.toLowerCase()
    if (instrumentId.includes('btc') || instrumentId.includes('eth')) {
      btcEthData.push(el)
    } else {
      restData.push(el)
    }
  })

  return [...btcEthData.sort(), ...restData.sort()]
}

export const DerivativeExTableApy: React.FC<DerivativeExTableProps> = ({
  columnLabels,
  data,
  title,
  contractType,
  width,
}) => {
  const columns: DataTableColumns<TransformedData> = useMemo(() => {
    return [
      {
        label: 'Instruments',
        render: row => row.instrumentId,
      },
      ...(columnLabels.map(colLabel => {
        return {
          label: colLabel.replace(/Future$/, ''),
          width,
          cellStyles: {
            padding: '0',
          },
          render: row => (
            <ExDataCell
              contractType={contractType}
              data={row.exchangeData[colLabel] || null}
            />
          ),
        }
      }) as DataTableColumns<TransformedData>),
    ]
  }, [contractType, columnLabels])

  const transformedData = transformData(data, columnLabels)

  return (
    <DataTable
      rowKey=""
      title={title ?? ''}
      columns={columns}
      data={transformedData}
    />
  )
}

import { DerivativeExData } from '../types/business'
import { isNullOrUndefined, toPercentStr } from '.'

export const toDisplayStr = (
  data: DerivativeExData,
  id: string | undefined
): string => {
  switch (id) {
    case 'Prem':
      return isNullOrUndefined(data.prem) ? 'N/A' : toPercentStr(data.prem)
    case 'APR':
      return isNullOrUndefined(data.curAPY) ? 'N/A' : toPercentStr(data.curAPY)
    case 'Absolute':
      return isNullOrUndefined(data.absolute)
        ? 'N/A'
        : `${+(Math.floor(data.absolute * 1000) / 1000).toPrecision(4)}`
    default:
      return 'N/A'
  }
}

export const toDetailedDisplayStrList = (
  data: DerivativeExData,
  id: string | undefined
): string[] => {
  switch (id) {
    case 'Prem':
      return [
        `${
          isNullOrUndefined(data.bidPrem) ? 'N/A' : toPercentStr(data.bidPrem)
        }`,
        `${
          isNullOrUndefined(data.askPrem) ? 'N/A' : toPercentStr(data.askPrem)
        }`,
      ]
    case 'APR':
      return [
        `${isNullOrUndefined(data.bidAPY) ? 'N/A' : toPercentStr(data.bidAPY)}`,
        `${isNullOrUndefined(data.askAPY) ? 'N/A' : toPercentStr(data.askAPY)}`,
      ]
    case 'Absolute':
      return [
        `${
          isNullOrUndefined(data.bidAbs)
            ? 'N/A'
            : `${+(Math.floor(data.bidAbs * 1000) / 1000).toPrecision(4)}`
        }`,
        `${
          isNullOrUndefined(data.askAbs)
            ? 'N/A'
            : `${+(Math.floor(data.askAbs * 1000) / 1000).toPrecision(4)}`
        }`,
      ]
    default:
      return ['N/A', 'N/A']
  }
}

export const toDisplayData = (
  data: DerivativeExData,
  id: string
): number | null => {
  switch (id) {
    case 'Prem':
      return data.prem
    case 'APR':
      return data.curAPY
    case 'Absolute':
      return data.absolute
    default:
      return null
  }
}

export const checkExCellSwitch = (id: string | undefined): boolean => {
  switch (id) {
    case 'Prem':
    case 'APY':
    case 'Absolute':
      return true
    default:
      return false
  }
}
